<template>
    <div v-loading="loading" v-if="client" class="prtf-page">
        <div class="prtf-page-header">
            <div class="prtf-page-header-title-holder">
                <div class="prtf-page-header-maintitle">
                    <div class="prtf-page-header-name"> {{client_data.first_name}} {{client_data.last_name}}</div>
                </div>
                <div class="prtf-page-header-subtitle">
                    <div class="prtf-page-header-subtitle_item">
                        ID {{client_data.id}} <ClipboardBtn :value="client_data.id" />
                    </div>
                    <div class="prtf-page-header-subtitle_item">
                        DB ID {{client_data._id}} <ClipboardBtn :value="client_data._id" />
                    </div>
                </div>
            </div>
        </div>

        <div class="prtf-page-body  prtf-page-body-flex">
            <div class="prtf-profile-row">
                <div class="prtf-profile-row-field">company:</div>
                <div class="prtf-profile-row-value">{{client_data.company}} <ClipboardBtn :value="client_data.company" /></div>
            </div>
            <div class="prtf-profile-row">
                <div class="prtf-profile-row-field">email:</div>
                <div class="prtf-profile-row-value">{{client_data.email}} <ClipboardBtn :value="client_data.email" /></div>
            </div>
            <div class="prtf-profile-row">
                <div class="prtf-profile-row-field">phone:</div>
                <div class="prtf-profile-row-value">{{client_data.phone}} <ClipboardBtn :value="client_data.phone" /></div>
            </div>
            <div class="prtf-profile-row">
                <div class="prtf-profile-row-field">description:</div>
                <div class="prtf-profile-row-value">{{client_data.description}} <ClipboardBtn :value="client_data.description" /></div>
            </div>
            <div class="prtf-profile-row" v-for="(field, index) in client_data.fields" :key="index">
                <div class="prtf-profile-row-field">{{field.field_name}}:</div>
                <div class="prtf-profile-row-value">{{field.field_value}} <ClipboardBtn :value="field.field_value" /></div>
            </div>
            <div class="prtf-profile-button">
                <el-button
                    type="primary"
                    @click="start_edit_profile"
                    v-if="can_edit_users">
                    <svg-icon icon-class="edit_pen"/>Edit
                </el-button>
            </div>

            <vxe-modal
                v-model="show_edit"
                title="Edit Profile"
                :width="modal_width"
                resize
                destroy-on-close
                :dblclickZoom="false"
                size="mini"
                className="tl-modal">

                <el-form label-position="top" :label-width="form_label_width" :model="form_data" :rules="form_rules" size="small" ref="Editor" autocomplete="off" class="tl-form">
                    <div class="tl-form-body">
                        <el-form-item label="First Name" prop="first_name">
                            <el-input v-model="form_data.first_name" minlength="1" />
                        </el-form-item>
                        <el-form-item label="Last Name" prop="last_name">
                            <el-input v-model="form_data.last_name" minlength="1" />
                        </el-form-item>
                        <el-form-item label="Analysts" prop="users" v-if="can_get_users">
                            <el-select v-model="form_data.users" multiple>
                                <el-option
                                    v-for="item in users"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div class="tl-form-row">
                            <el-form-item label="Company" prop="company">
                                <el-input v-model="form_data.company" />
                            </el-form-item>
                            <el-form-item label="Email" prop="email">
                                <el-input v-model="form_data.email" />
                            </el-form-item>
                            <el-form-item label="Phone" prop="phone">
                                <el-input v-model="form_data.phone" @keypress.native="is_number($event)" />
                            </el-form-item>
                        </div>

                        <el-form-item label="Description" prop="description">
                            <el-input v-model="form_data.description" />
                        </el-form-item>

                        <div v-for="(field, index) in form_data.fields" :key="index" class="custom_fields-row">
                            <el-form-item label="Custom Field Name" prop="field_name">
                                <el-input v-model="field.field_name" />
                            </el-form-item>
                            <el-form-item label="Custom Field Value" prop="field_value" class="spacer">
                                <el-input v-model="field.field_value" />
                            </el-form-item>
                            <div class="custom_fields-row_del">
                                <el-button type="text" size="mini" @click="form_data.fields.splice(index, 1);">
                                    <i class="el-icon-close"></i>
                                </el-button>
                            </div>
                        </div>
                        <div>
                            <el-button type="text" size="mini" @click="form_data.fields.push({field_name:'', field_value:''})"><i class="el-icon-plus"></i> custom field</el-button>
                        </div>
                    </div>

                    <div class="tl-form-buttons">
                        <div class="spacer" />
                        <el-button @click="show_edit = false">Cancel</el-button>
                        <el-button type="primary" @click="save_profile">Save</el-button>
                    </div>
                </el-form>
            </vxe-modal>
        </div>
    </div>
</template>

<script>
import ClipboardBtn from "@/components/PageComponents/ClipboardBtn"
import $ from "jquery";
import {mapState} from "vuex";

export default {
    name: 'client-profile',
    components: { ClipboardBtn },

    props: {
        client: {
            required: true,
        },
    },

    data(){
        return {
            module: 'clients',
            show_edit: false,
            loading: false,
            form_label_width: '140px',
            form_data: {},
            form_rules: {
                first_name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                last_name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                users: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ],
                phone: [
                    {trigger: 'blur', validator (rule, value, callback) {
                            if (!value || /[\d]/.test(value)) {
                                callback();
                            } else {
                                callback(new Error('Phone number must have digits only'));
                            }
                        }}
                ],
                email: [
                    {trigger: 'blur', validator (rule, value, callback) {
                            if (!value || /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(value)) {
                                callback();
                            } else {
                                callback(new Error('Not valid email'));
                            }
                        }}
                ]
            },
            client_data: {...this.client}
        }
    },

    computed: {
        ...mapState({
            user  : state => state.app.user,
            users : state => state.users.options,
        }),
        modal_width(){
            let w
            if ($(window).width() <= 600)
                w = $(window).width()
            else if ($(window).width() > 600 && $(window).width() <= 800)
                w = $(window).width() * 0.8
            else
                w = $(window).width() * 0.5
            return w
        },
        can_get_users(){
            return (this.user && this.user.rights) ? this.user.rights['get-users'] : false;
        },
        can_edit_users(){
            return (this.user && this.user.rights) ? this.user.rights['edit-users'] : false;
        }
    },

    methods: {
        start_edit_profile(){
            this.form_data = JSON.parse(JSON.stringify(this.client_data))
            this.show_edit = true
        },
        save_profile(){
            this.$refs.Editor.validate((valid) => {
                if (valid) {
                    this.$refs.Editor.clearValidate()
                    this.show_edit = false;
                    this.loading = true
                    this.$store.dispatch(this.module + '/updateItem', this.form_data)
                    .then(() =>
                        this.$store.dispatch('clients/getClient', this.client.id).then((client_response) => {
                            this.client_data = client_response
                            this.loading = false
                            this.$store.commit(this.module + '/set', {type: 'current', items: client_response});
                        })
                    )
                } else {
                    return false;
                }
            });
        },
        is_number(evt){
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    },
}
</script>
<style scoped>
.prtf-profile-button {
    font-size: 15px;
}
.prtf-profile-button .icon-edit_pen {
    padding-right: 10px;
}
.prtf-profile-button .icon-edit_pen {
    stroke: #fff;
}
</style>
<template>
    <el-button 
        type="text" 
        v-if="value"
        v-clipboard="value"
        v-clipboard:success="clipboard_success_handler"
        v-clipboard:error="clipboard_error_handler">
        <svg-icon icon-class="copy" style="width: 10px; height: 10px; padding-bottom: 5px;" />
    </el-button>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
    },

    methods: {
        clipboard_success_handler({ value, event }){
            this.$message({message: 'Copied to clipboard'})
        },
        clipboard_error_handler({ value, event }){
            this.$message({message: 'Cannot copy to clipboard', type: 'error'})
        }
    }
}
</script>
